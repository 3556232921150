import {
    genPageTheme,
    shapes,
    createBaseThemeOptions,
    createUnifiedTheme,
    palettes,
} from '@backstage/theme';

const bannerColors = ['#2B59E4', '#14B8A6']

const ciano = createUnifiedTheme({
    ...createBaseThemeOptions({
        palette: {
            ...palettes.light,
            primary: {
                main: '#2b59e4',
            },
            secondary: {
                main: '#6366F1',
            },
            error: {
                main: '#f94040',
            },
            warning: {
                main: '#FDC500',
            },
            info: {
                main: '#34548a',
            },
            success: {
                main: '#15ab51',
            },
            background: {
                default: '#fff',
                paper: '#FAFAFA',
            },
            banner: {
                info: '#818CF8',
                error: '#f94040',
                text: '#343b58',
                link: '#2b59e4',
            },
            errorBackground: '#8c4351',
            warningBackground: '#8f5e15',
            infoBackground: '#343b58',
            navigation: {
                background: '#2043AB',
                indicator: '#fff',
                navItem: {
                    hoverBackground: '#2750CD',
                },
                color: '#D4D4D8',
                selectedColor: '#ffffff',
            },
        },
    }),
    defaultPageTheme: 'home',
    // fontFamily: 'Comic Sans MS',
    /* below drives the header colors */
    pageTheme: {
        home: genPageTheme({ colors: bannerColors, shape: shapes.round, options: { fontColor: "#fff" } }),
        documentation: genPageTheme({
            colors: bannerColors,
            shape: shapes.wave2,
        }),
        tool: genPageTheme({ colors: bannerColors, shape: shapes.round }),
        service: genPageTheme({
            colors: bannerColors,
            shape: shapes.wave,
        }),
        website: genPageTheme({
            colors: bannerColors,
            shape: shapes.wave2,
        }),
        library: genPageTheme({
            colors: bannerColors,
            shape: shapes.round,
        }),
        other: genPageTheme({ colors: bannerColors, shape: shapes.round }),
        app: genPageTheme({ colors: bannerColors, shape: shapes.wave2 }),
        apis: genPageTheme({ colors: bannerColors, shape: shapes.wave }),
    },
});

export default ciano